import React from "react";
import "./App.scss";
import myImage from "./asset/amit.png";
import logo from "./asset/logo192.png";
function App() {
  return (
    <section className="App">
      <section className="header">
        <a
          href="https://stackoverflow.com/users/1509905/sengupta-amit"
          target="_blank"
          rel="noopener noreferrer"
          className="headerLogo"
        >
          <img src={logo} alt="logo" />
        </a>
        <ul>
          <li>
            <a
              className="anchor-strike"
              href="https://stackoverflow.com/users/1509905/sengupta-amit"
              target="_blank"
              rel="noopener noreferrer"
            >
              Portfolio
            </a>
          </li>
          <li>
            <a
              className="anchor-strike"
              href="https://www.linkedin.com/in/amit-sengupta/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Labs
            </a>
          </li>
          <li>
            <a
              className="anchor-strike"
              href="https://github.com/amitse"
              target="_blank"
              rel="noopener noreferrer"
            >
              About
            </a>
          </li>
        </ul>
      </section>
      <section className="hero">
        <section className="left">
          <b className="cmd"></b>
          <h1>Let's try something new.</h1>
          <p>
            <span aria-label="wave hand emoji">👋</span> Hello,
            <a
              className="anchor-strike"
              data-hover-text="Contact Me"
              href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%63%6F%6E%74%61%63%74%40%61%6D%69%74%73%65%6E%67%75%70%74%61%2E%6F%72%67"
            >
              I’m Amit
            </a>
            , a software professional with 6+ years of experience in User
            Experience. Currently working with Microsoft in Hyderabad.
          </p>
          <ul>
            <li>
              <a
                className="anchor-strike"
                href="https://stackoverflow.com/users/1509905/sengupta-amit"
                target="_blank"
                rel="noopener noreferrer"
              >
                StackOverflow
              </a>
            </li>
            <li>
              <a
                className="anchor-strike"
                href="https://www.linkedin.com/in/amit-sengupta/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                className="anchor-strike"
                href="https://github.com/amitse"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </a>
            </li>
            <li>
              <a
                className="anchor-strike"
                href="https://github.com/amitse"
                target="_blank"
                rel="noopener noreferrer"
              >
                Medium
              </a>
            </li>
          </ul>

          <a
            className="anchor-strike callToAction"
            href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%63%6F%6E%74%61%63%74%40%61%6D%69%74%73%65%6E%67%75%70%74%61%2E%6F%72%67"
          >
            Contact Me
          </a>
        </section>
        <section className="image">
          <img src={myImage} alt="Amit Sengupta" />
        </section>
      </section>

      <section className="lab">
        <section>
          <h2>LAB</h2>
        </section>
      </section>
    </section>
  );
}

export default App;
